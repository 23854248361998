import React, { useState, useEffect, useCallback } from "react";
import "./About.css";

const MAX_POSTS = 1;

// Debounce function (can be defined outside of the component)
function debounce(func, wait, immediate) {
  let timeout;
  return function () {
    const context = this,
      args = arguments;
    const later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const About = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allPostsLoaded, setAllPostsLoaded] = useState(false); // Add this line

  // Load more posts logic
  const loadMorePosts = useCallback(async () => {
    setLoading(true);

    // Simulate dynamic content loading
    // Inside loadMorePosts function
    setTimeout(() => {
      if (page >= MAX_POSTS) {
        setAllPostsLoaded(true); // No more posts to load
      } else {
        const newPosts = [`Post ${page}`, `Post ${page + 1}`];
        setPosts((prevPosts) => [...prevPosts, ...newPosts]);
        setPage((page) => page + 2);
      }
      setLoading(false);
    }, 1000);
  }, [page]);

  // Infinite scrolling logic
  useEffect(() => {
    const handleScroll = () => {
      // If loading or all posts are loaded, don't do anything
      if (loading || allPostsLoaded) return;

      // Check if the user is near the bottom of the page, add threshold for mobile browsers
      const threshold = 100; // Pixels from the bottom to start loading more posts
      if (
        window.innerHeight + document.documentElement.scrollTop + threshold >=
        document.documentElement.offsetHeight
      ) {
        loadMorePosts();
      }
    };

    // Debounce the scroll event
    const debouncedHandleScroll = debounce(handleScroll, 100);

    window.addEventListener("scroll", debouncedHandleScroll);
    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [loading, loadMorePosts, allPostsLoaded]); // Add allPostsLoaded to dependencies array

  return (
    <div className="about-content">
      <h1>About Us</h1>
      <p>
      Our articles, research papers, and blog posts delve into the intricacies of AI, exploring everything from foundational algorithms to the latest trends in Machine Learning and Deep Learning. We dissect the complexities of Cloud Computing, offering insights into cloud infrastructure, security, and emerging technologies like Quantum Computing and Edge Computing.
      </p>
      <p></p>
      <h2>Our Story</h2>
      <p>
      In a world where AI, deep learning, and cloud technologies evolve at lightning speed, understanding them can feel overwhelming. Our mission is simple: to demystify complex tech concepts and make them accessible to everyone. Through clear, insightful articles, we break down jargon and bring clarity to innovation, empowering you to stay informed and ahead in the digital age.
      </p>
      <h2>Our Audience: The True North of Our Endeavor</h2>
      <p>Our audience is our true north. We're more than a platform; we're a community. A place where tech veterans and novices alike can learn, engage, and contribute to the ever-evolving narrative of AI and Cloud Technology.</p>
      <h2>Our Mission</h2>
      <p>
      In a world where technology's pace often outstrips comprehension, our mission stands clear: to empower our readers by transforming the complex into the comprehensible. We're committed to making AI and Cloud Technology accessible, engaging, and enlightening for all, irrespective of their technical background.
      </p>
      {posts.map((post, index) => (
        <p key={index}>{post}</p>
      ))}
      {loading && <p>Loading...</p>}
    </div>
  );
};

export default About;